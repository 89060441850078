<template>
  <div class="layout">
    <div class="left-menu">
      <LeftMenu />
    </div>
    <div class="right-main">
      <TopMenu class="top-menu" />
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "./left-menu.vue";
import TopMenu from "./top-menu.vue";
export default {
  components: {
    LeftMenu,
    TopMenu,
  },
};
</script>

<style lang="less" scoped>
.layout {
  height: 100%;
  display: flex;
  .left-menu {
    flex: 0 0 1.44rem;
    width: 1.44rem;
    background-color: #081429;
  }
  .right-main {
    width: calc(100% - 1.44rem);
    background-color: #e7e6eb;
    .top-menu {
      height: 0.68rem;
      background-color: #ffffff;
    }
    .content{
      padding: 10px;
      box-sizing: border-box;
      height: calc(100% - 0.68rem);
    }
  }
}
</style>