<template>
  <div class="left-menu">
    <div class="logo">
      <img src="../../../assets/optimizeImgs/logo.png" alt />
      <div class="text">云体系</div>
    </div>
    <div class="menu">
      <div
        class="menu-item"
        v-for="(item, index) in menuList"
        :key="index"
        :class="{ active: menuIndex == index }"
        @click="handleMenu(item,index)"
      >
        <div>
          <img :src="menuIndex === index ? item.img2 : item.img1" alt />
          <div class="menu-title">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuIndex: 0,
      menuList: [
        {
          title: "文件分解",
          img1: require("../../../assets/optimizeImgs/file1.png"),
          img2: require("../../../assets/optimizeImgs/file2.png"),
          path: "/resolve",
        },
        {
          title: "文件接口表",
          img1: require("../../../assets/optimizeImgs/port1.png"),
          img2: require("../../../assets/optimizeImgs/port2.png"),
          path: "/port",
        },
        {
          title: "系统设置",
          img1: require("../../../assets/optimizeImgs/set1.png"),
          img2: require("../../../assets/optimizeImgs/set2.png"),
          path: "/set",
        },
        {
          title: "优化检讨",
          img1: require("../../../assets/optimizeImgs/review1.png"),
          img2: require("../../../assets/optimizeImgs/review2.png"),
          path: "/review",
        },
      ],
    };
  },
  created() {
    this.menuIndex = sessionStorage.getItem("systemIndex") || 0;
  },
  methods: {
    handleMenu(item, index) {
      this.menuIndex = index;
      sessionStorage.setItem("systemIndex", index);
      sessionStorage.setItem("systemItemIndex", 0);
      this.$router.push(item.path);
    },
  },
  destroyed() {
    sessionStorage.removeItem("systemIndex");
    sessionStorage.removeItem("systemItemIndex");
  }
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: PingFang SC;
  src: url("../../../assets/font/PingFang-SC.ttf");
}
.left-menu {
  padding: 0.42rem 0 0 0;
  text-align: center;
  .logo {
    img {
      width: 0.8rem;
      height: 0.5rem;
    }
    .text {
      margin-top: 0.15rem;
      height: 0.2rem;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 0.2rem;
      color: #a1ddff;
      line-height: 0.2rem;
    }
  }
  .menu {
    margin-top: 0.1rem;
    .menu-item {
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 0.34rem;
        height: 0.34rem;
      }
      .menu-title {
        height: 0.16rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 0.16rem;
        color: #8ba2ce;
        line-height: 0.16rem;
      }
    }
    .active {
      height: 1.33rem;
      background: url(../../../assets/optimizeImgs/action.png) no-repeat 100%;
      background-size: 100% 100%;
      .menu-title {
        color: #ffffff;
      }
    }
  }
}
</style>