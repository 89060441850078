<template>
  <div class="top-menu">
    <div class="home" @click="handleHome">
      <img src="../../../assets/optimizeImgs/home.png" alt />
      <span>首页</span>
    </div>
    <div class="menu">
      <div
        class="menu-item"
        v-for="(item, index) in menuList"
        :key="index"
        :class="{ active: menuIndex == index }"
        @click="handleMenu(item,index)"
      >{{ item.meta.title }}</div>
    </div>
    <div class="log-out" @click="loginUp">退出登录</div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      parentRoute: this.$route.matched[0].path,
      menuList: [],
      menuIndex: 0,
    };
  },
  watch: {
    // 监听路由变化
    $route(to) {
      this.menuIndex = sessionStorage.getItem("systemItemIndex") || 0;
      this.parentRoute = to.matched[0].path;
      this.$router.options.routes.forEach((item) => {
        if (item.path == this.parentRoute) {
          if (item.redirect) {
            this.menuList = item.children;
          } else {
            this.menuList = [];
          }
        }
      });
    },
  },

  created() {
    this.menuIndex = sessionStorage.getItem("systemItemIndex") || 0;
    this.$router.options.routes.forEach((item) => {
      if (item.path == this.parentRoute) {
        if (item.redirect) {
          this.menuList = item.children;
        }
      }
    });
  },
  mounted() {},
  methods: {
    handleHome() {
      this.$router.push("/home");
    },
    handleMenu(item, index) {
      this.menuIndex = index;
      sessionStorage.setItem("systemItemIndex", index);
      this.$router.push(item.path);
    },
    loginUp() {
      this.$message({
        type: "success",
        message: "退出成功",
      });
      window.sessionStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    },
  },
};
</script>

<style lang="less" scoped>
.top-menu {
  padding: 0 0.36rem 0 0.28rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .home {
    cursor: pointer;
    img {
      vertical-align: middle;
      width: .26rem;
      height: .26rem;
    }
    span {
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 0.16rem;
      vertical-align: middle;
    }
  }
  .menu {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 0.2rem;
    .menu-item {
      height: 100%;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 0.16rem;
      color: #000000;
      padding: 0 0.1rem;
      margin-right: 0.38rem;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .active {
      background-color: #f2f5ff;
      font-weight: bold;
      color: #5a8bff;
      border-bottom: 0.04rem solid #5a8bff;
    }
  }
  .log-out {
    height: 0.21rem;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 0.18rem;
    color: #2c7aff;
    padding: 0 0.1rem;
    border-left: 2px solid #e9e9e9;
    border-right: 2px solid #e9e9e9;
    line-height: 0.21rem;
    cursor: pointer;
  }
}
</style>